 @import url('https://fonts.googleapis.com/css2?family=Fira+Sans&family=Philosopher:wght@400;700&display=swap');

 @import 'tailwindcss/base';
 @import 'tailwindcss/components';
 @import 'tailwindcss/utilities';

 h2,p{
font-family: 'Philosopher', sans-serif;
 }

 span,button,ul{
  font-family: 'Fira Sans', sans-serif;
 }

 .slider {
  width: 100%;
  height: 2px;
  background-color: #cdd9ed;
  margin-top: 20px;
 }

 .slider .thumb {
  width: 23px;
  height: 23px;
  cursor: grab;
  background: #fff;
  border-radius: 50%;
  border: 2px solid #765827;
  top: -12px;
 }

 .track-1{
  background-color: #765827;
 }






 

